import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useShop } from '../../contexts/ShopContext';
import EditShopModal from './EditShopModal';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formControl: {
        minWidth: 150
    },
    cell: {
        marginRight: 20
    }
});

export default function ShopTable() {
    const classes = useStyles();
    const { shops, deleteShopFromAllShopsAndFilteredShops } = useShop();
    const [open, setOpen] = useState(false);
    const [editShop, setEditShop] = useState({});

    return (
        <div>
            <TableContainer component={Paper} >
                <Table className={classes.table} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Shop URL</TableCell>
                            <TableCell align="center">Platform</TableCell>
                            <TableCell align="center">Tags</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shops.map((shop) => (
                            <TableRow key={shop.id}>
                                <TableCell align="center" component="th" scope="row">
                                    {shop.url}
                                </TableCell>
                                <TableCell align="center">{shop.flatform}</TableCell>
                                <TableCell align="center">{shop.tags.toString()}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() =>{
                                            setOpen(true);
                                            setEditShop(shop)
                                        }}
                                        startIcon={<EditIcon />}
                                        className={classes.cell}
                                    >
                                        Edit
                                        </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={() => {
                                            deleteShopFromAllShopsAndFilteredShops(shop.id);
                                        }}
                                        startIcon={<DeleteIcon />}
                                    >
                                        Delete
                                         </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditShopModal 
                open={open} 
                setOpen={setOpen} 
                selectedEditShop={editShop}
                setSelectedEditShop={setEditShop}
            />
        </div>
    );
}
