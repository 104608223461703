import React from 'react'
import Appbar from '../components/Navbar/Appbar';
import ProductGrid from '../components/Homepage/ProductGrid';
import Tools from '../components/Homepage/Tools';

export default function Home() {
    return (
        <div>
            <Appbar />
            <Tools />
            <ProductGrid />
        </div>
    )
}

