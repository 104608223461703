import React, { createContext, useContext, useState, useEffect } from 'react';
import {firestore} from '../config/firebase';

const BlackListContext = createContext();

export function useBlackList(){
    return useContext(BlackListContext);
}

export function BlackListProvider({ children }) {
    const [blackList, setBlackList] = useState([]);

    useEffect(() => {
    	getBlackList();
    }, []);

    const getBlackList = async () => {
        const data = await firestore.collection("blacklist").get();
        data.forEach(doc => {
            const shop = doc.data();
            setBlackList(blackList => [...blackList, shop.url]);
        });
    }
    
    const value = { 
        blackList,
    };

    return (
        <BlackListContext.Provider value={value}>
            { children }
        </BlackListContext.Provider>
    )
}
