import React, { createContext, useContext, useState, useEffect } from 'react';
import {auth, firestore} from '../config/firebase';

const AuthContext = createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password);
    }

    function signUp(email, password){
        return auth.createUserWithEmailAndPassword(email, password)
        .then(cred => {
            firestore.collection('users').doc(cred.user.uid).set({email: email});
        });
    }

    function logout(){
        return auth.signOut();
    }

    useEffect(() => {
        const unsubcriber = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);    
        });
        return unsubcriber;
    }, []);

    const value = { currentUser, login, signUp, logout };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children }
        </AuthContext.Provider>
    )
}
