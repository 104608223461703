import React from 'react'

import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';

import { useProduct } from '../../contexts/ProductContext';
import { useShop } from '../../contexts/ShopContext';

const useStyles = makeStyles({
    formControl: {
        minWidth: 300,
        maxWidth: 500,
        marginRight: 40
    }
})

export default function ShopSelect () {
    const { selectedShop, setSelectedShop, shopsFilteredByTags } = useShop();
    const { setProducts, setCurrentPage } = useProduct();
    const classes = useStyles();

    const handleChange = (e) => {
        setSelectedShop(e.target.value);
        setProducts([]);
        setCurrentPage(1);
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Shop</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedShop}
                onChange={handleChange}
            >
                <MenuItem value='all'>All</MenuItem>
                {
                    shopsFilteredByTags.map(shop => (
                        <MenuItem key={shop.id} value={ shop.url }>{ shop.url }</MenuItem>
                    ))   
                }   
            </Select>
        </FormControl>
    )
}
