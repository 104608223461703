import React, { useState } from 'react';
import axios from 'axios';

import {
    Button
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';

import { useShop } from '../../contexts/ShopContext';
import useInput from '../../hooks/useInput';
import TagsCRUD from './TagsCRUD';

const fetchInitData = async (url, flatform) => {
    switch (flatform) {
        case 'shopbase': {
            return fetchInitDataShopbase(url);
        }
        case 'shopify': {
            return fetchInitDataShopify(url);
        }
        case 'woocommerce': {
            return fetchInitDataWoocommerce(url);
        }
        default: break;
    }
}

const fetchInitDataShopbase = async (url) => {
    const res = await axios.get(`${url}/api/catalog/products_v2.json?limit=30&page=1`);
    const products = res.data.products.map(rawData => (
        {
            id: rawData.id,
            link: url + '/' + rawData.handle,
            created_at: rawData.created_at,
            image: rawData.images[0] ? rawData.images[0].src : 'no-image.png'
        }
    ));
    return products;
}

const fetchInitDataWoocommerce = async (url) => {
    const res = await axios.get(`${url}/wp-json/wp/v2/product?per_page=30&page=1`);

    for (const product of res.data) {
        if (product.featured_media !== 0) {
            const productMedia = await axios.get(
                `${url}/wp-json/wp/v2/media/${product.featured_media}`
            );
            product.image = productMedia.data.source_url;
        } else {
            product.image = 'no-image.png';
        }
    }

    const products = res.data.map(rawData => (
        {
            id: rawData.id,
            link: rawData.link,
            created_at: rawData.date,
            image: rawData.image ? rawData.image : 'no-image.png'
        }
    ));

    return products;
}

const fetchInitDataShopify = async (url) => {
    const res = await axios.get(`${url}/products.json?limit=30&page=1`);
    const products = res.data.products.map(rawData => (
        {
            id: rawData.id,
            link: url + '/' + rawData.handle,
            created_at: rawData.created_at,
            image: rawData.images[0] ? rawData.images[0].src : 'no-image.png'
        }
    ));
    return products;
}

export default function FormDialog() {
    const [open, setOpen] = useState(false);
    const [flatform, setFlatform] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [errors, setErrors] = useState('');
    const [checking, setChecking] = useState(false);
    const [done, setDone] = useState(false);
    const [adding, setAdding] = useState(false);
    const [newTag, setNewTag] = useState('');

    const url = useInput('');
    const { addNewShopToAllShopsAndFilteredShops } = useShop();

    const handleClickOpen = () => {
        setOpen(true);
        setErrors('');
    };

    const handleCheck = async (url) => {
        setErrors('');
        setChecking(true);

        if (url.value[url.value.length - 1] !== '/') {
            url.setValue(url.value + '/');
        }

        if (!url.value.includes('https')) {
            setErrors('Url must start with "https://"');
        } else {
            setFlatform('');
            try {
                let res = await axios.get(`${url.value}/api/catalog/products_v2.json`);
                if (res) {
                    setFlatform('shopbase');
                }
            } catch (error) {
                console.clear();
                try {
                    let res = await axios.get(`${url.value}/wp-json/wp/v2/product`);
                    if (res) {
                        setFlatform('woocommerce');
                    }
                } catch (error) {
                    console.clear();
                    try {
                        let res = await axios.get(`${url.value}/products.json`);
                        if (res) {
                            setFlatform('shopify');
                        }
                    } catch (error) {
                        console.clear();
                        setErrors('Unknown platform');
                    }
                }
            }
        }
        setDone(true);
        setChecking(false);
    }

    const handleAdd = () => {
        let initData = [];
        (async () => {
            setAdding(true);
            addNewShopToAllShopsAndFilteredShops(url.value, flatform, selectedTags, initData);
            setDone(false)
            setOpen(false);
            url.clearInput();
            setErrors('');
            setAdding(false);
        })();
    }

    const handleClose = () => {
        setOpen(false);
        url.clearInput();
        setNewTag('');
        setFlatform('');
        setSelectedTags([]);
        setDone(false);
    };

    return (
        <div>
            <div className="add-shop">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleClickOpen}
                >
                    Add new shop
                </Button>
            </div>
            <Dialog open={open} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add a new shop</DialogTitle>
                {errors && <Alert severity="error">{errors}</Alert>}
                <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="url"
                            label="Shop URL"
                            type="text"
                            value={url.value}
                            onChange={url.onChange}
                            fullWidth
                            required
                        />
                    </div>
                    <TagsCRUD newTag={newTag} setNewTag={setNewTag} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={adding}>
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        handleCheck(url);
                    }}
                        color="primary"
                        disabled={checking || adding}
                    >
                        {checking ? 'Checking...' : 'Check'}
                    </Button>
                    <Button onClick={() => {
                        handleAdd();
                    }}
                        color="primary"
                        disabled={(!errors && done && !checking) ? false : true}
                    >
                        {adding ? 'Adding...' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
