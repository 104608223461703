
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Grid, makeStyles } from '@material-ui/core';

import ProductCard from './ProductCard'
import ScrollToTop from './ScrollToTop';
import Loader from '../Loader';

import { useShop } from '../../contexts/ShopContext';
import { useProduct } from '../../contexts/ProductContext';

import '../../App.css';

const useStyle = makeStyles({
    center: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 20
    },
    findingImage: {
        width: 500,
        height: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    text: {
        textAlign: 'center'
    }
})

export default function ProductGrid() {
    const { products, loadMore } = useProduct();
    const [loading, setLoading] = useState(false);
    const { shops, loadingShops } = useShop();
    const classes = useStyle();

    useEffect(() => {
        products.length > 0 ? setLoading(false) : setLoading(true);
    }, [products]);

    if (shops.length === 0 && loadingShops === false) {
        return (
            <div className={classes.center}>
                <h1 className={classes.text}>There are no shops to display yet.</h1>
                <img className={classes.findingImage} src="finding.png" alt="finding" />
            </div>
        )
    } else {
        return (
            <InfiniteScroll
                dataLength={products.length}
                next={loadMore}
                hasMore={true}
                loader={Loader}
                style={{ overflow: 'hidden' }}
            >
                <ScrollToTop showBelow={250} />
                {loading
                    ? <Loader />
                    : (
                        <Grid container spacing={2} justify={'center'}>
                            {products.map(product => (
                                <Grid key={product.id} item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'}>
                                    <ProductCard product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    )
                }
            </InfiniteScroll>
        )
    }
}
