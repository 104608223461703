import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Link, useHistory } from 'react-router-dom';
import Notification from "./Notification";

import { useAuth } from "../../contexts/AuthContext";


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        flexGrow: 1,
    },
    logoImg: {
        maxWidth: 40
    },
    inline: {
        display: 'flex'
    },
    link:{
        textDecoration: 'none', 
        color: 'black'
    },
    menu:{
        top: 20
    },
    menuItem:{
        marginLeft: 5
    }
}));

export default function Appbar() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { logout, currentUser } = useAuth();
    const history = useHistory();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        history.push('/');
    }

    return (
        <div className={classes.root} >
            <AppBar position="sticky" color="inherit" >
                <Toolbar>
                    <Link to='/' className={classes.logo}>
                        <img src='logoG.png' className={classes.logoImg} alt="logo" />
                    </Link>
                    <div className={classes.inline}>
                        <Notification />
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={open}
                            onClose={handleClose}
                            className={classes.menu}
                        >
                            <Link to='/shops' className={classes.link}>
                                <MenuItem>
                                    <StorefrontOutlinedIcon />
                                    <span className={classes.menuItem}>Shops management</span>
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={handleLogout}>
                                <ExitToAppIcon />
                                <span className={classes.menuItem}>Logout ({currentUser.email})</span>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
