import React, { useEffect, useState } from 'react'
import moment from 'moment';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    productCard: {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", 
        transition: 0.3, 
        maxWidth: 300, 
        heigh: 350,
        "&:hover": {
            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
        }
    },
    content: {
        marginLeft: 15
    },
    contentPrice: {
        marginBottom: 0,

    },
    contentTimestamp: {
        marginBottom: 0,
        marginTop: 0
    },
    actions: {
        marginLeft: 15
    }
})

export default function ProductCard({ product }) {
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState('spinner.gif');
    const [price, setPrice] = useState('unknown');
    const [date, setDate] = useState('');
    const [productUrl, setProductUrl] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const setProductDetail = async () => {
            switch (product.flatform) {
                case 'shopify': {
                    if (!product.images) setImageUrl('no-image.png');
                    else setImageUrl(product.images[0].src);
                    setPrice(product.variants[0].price);
                    setProductUrl(`${product.shopUrl}products/${product.handle}`);
                    setDate(product.created_at);
                    break;
                }
                case 'woocommerce': {
                    const productMedia = await axios.get(
                        `${product.shopUrl}wp-json/wp/v2/media/${product.featured_media}`
                    );
                    if(productMedia.data.source_url){
                        setImageUrl(productMedia.data.source_url);
                    } else {
                        setImageUrl('no-image.png');
                    }
                    setDate(product.date);
                    setProductUrl(product.link);
                    break;
                }
                case 'shopbase': {
                    if (!product.images) setImageUrl('no-image.png');
                    else setImageUrl(product.images[0].src);
                    setPrice(product.price);
                    setProductUrl(`${product.shopUrl}products/${product.handle}`);
                    setDate(moment.unix(product.created_at));
                    break;
                }
                default: break;
            }
        }
        setProductDetail();
    }, [imageUrl]);

    return (
        <div className={classes.productCard}>
            <LazyLoad height={270}>
                <img src={!imageLoaded ? 'spinner.gif' : imageUrl} alt="product" style={{ width:300, height: 300 }} onLoad={() => setImageLoaded(true)} />
                <div className={classes.content}>
                    <p className={classes.contentPrice}>
                        {price === 'unknown' ? price : price + ' $'}
                    </p>
                    <p className={classes.contentTimestamp}>
                        Created at: {moment(date).format("DD/MM/YYYY")}
                    </p>
                    <a href={product.shopUrl}>
                        <Typography gutterBottom component="p">
                            {product.shopUrl}
                        </Typography>
                    </a>
                </div>
                <div className={classes.actions}>
                    <Button
                        size="small"
                        color="primary"
                        href={productUrl}
                        target="_blank"
                    >Go to the post</Button>
                </div>
            </LazyLoad>
        </div>
    )
}
