import React, { createContext, useContext, useState, useEffect } from 'react';
import {firestore} from '../config/firebase';
import { useAuth } from './AuthContext';

const TagContext = createContext();

export function useTag(){
    return useContext(TagContext);
}

export function TagProvider({ children }) {
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const {currentUser} = useAuth();

    useEffect(() => {
        if(currentUser){
            getTags();
        };
    }, [currentUser]);

    const getTags = async () => {
        const data = await firestore.collection("tags").where('userId', '==', currentUser.uid).get();
        data.forEach(doc => {
            const tag = doc.data();
            tag.id = doc.id;
            setTags(tags => [...tags, tag]);
        });
    }

    const addTag = async (tag) => {
        const newTag = { tagname: tag, userId: currentUser.uid }
        const newDoc = await firestore.collection("tags").add(newTag);
        newTag.id = newDoc.id;
        setTags([...tags, newTag]);
    }

    const editTag = async (tagId, tagName) => {
        const changedTagsArr = tags;
        const changes = { tagname: tagName };
        const tagRef = firestore.collection("tags").doc(tagId);
        await tagRef.update(changes);
        for(let i = 0; i < changedTagsArr.length; i++){
            if(changedTagsArr[i].id === tagId){
                changedTagsArr[i].tagname = tagName;
            }
        }
        setTags(changedTagsArr);
    }

    const deleteTag = async (tagId) => {
        const newTags = tags.filter(tag => {
            return tag.id !== tagId;
        });
        firestore.collection("tags").doc(tagId).delete();
        setTags(newTags);
    }
    
    const value = { 
        tags,
        setTags,
        getTags,
        addTag,
        editTag,
        deleteTag,
        selectedTags,
        setSelectedTags
    };

    return (
        <TagContext.Provider value={value}>
            { children }
        </TagContext.Provider>
    )
}
