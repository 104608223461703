import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from '../config/firebase';
import { anyMatchInArray } from '../utils/index';
import { useAuth } from "./AuthContext";
import { useTag } from "./TagContext";

const ShopContext = createContext();

export function useShop() {
    return useContext(ShopContext);
}

export function ShopProvider({ children }) {
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState('all');
    const [shopsFilteredByTags, setShopsFilteredByTags] = useState([]);
    const [flag, setFlag] = useState(false);
    const [loadingShops, setLoadingShops] = useState(true);

    const {currentUser} = useAuth();
    const { selectedTags, setSelectedTags } = useTag();

    useEffect(() => {
        if(currentUser){
            setShops([]);
            setShopsFilteredByTags([]);
            getShops();
        } else {
            setShopsFilteredByTags([]);
        }
    }, [flag, currentUser]);

    const getShops = async () => {
        let temp = [];
        setLoadingShops(true);
        const snapshot = await firestore.collection("shops").where('userId', '==', currentUser.uid).get();
        snapshot.forEach(doc => {
            const shop = doc.data();
            shop.id = doc.id;
            temp = [...temp, shop];
            
        });
        setShops(temp);
        setShopsFilteredByTags(temp);
        setLoadingShops(false);
    }

    const addNewShopToAllShopsAndFilteredShops = async (url, flatform, tags, initData) => {
        const newShop = {
            url,
            flatform,
            tags,
            latestData: initData,
            userId: currentUser.uid
        }

        const newDoc = await firestore.collection("shops").add(newShop);
        newShop.id = newDoc.id;
        setShops([...shops, newShop]);
        // add to filterd shops array if has filtering tag
        if (selectedTags.length === 0 || anyMatchInArray(newShop.tags, selectedTags)) {
            setShopsFilteredByTags(shopsFilteredByTags => [...shopsFilteredByTags, newShop]);
        }
    }

    const editShop = async (id, url, flatform, tags) => {
        const changes = { url: url, flatform: flatform, tags: tags };
        const shopRef = firestore.collection("shops").doc(id);
        await shopRef.update(changes);

        let changedShops = shops;
        for(let i = 0; i < changedShops.length; i++){
            if(changedShops[i].id === id){
                changedShops[i].tags = tags;
            }
        }
        setFlag(flag => !flag);
        setShops(changedShops);
    }

    const deleteShopFromAllShopsAndFilteredShops = (id) => {
        const newShopsArr = shops.filter(item => {
            return item.id !== id;
        });
        const newFilteredShopsArr = shopsFilteredByTags.filter(item => {
            return item.id !== id;
        })
        firestore.collection("shops").doc(id).delete();
        setShops(newShopsArr);
        setShopsFilteredByTags(newFilteredShopsArr);
    }

    useEffect(() => {
        setShopsFilteredByTags([]);
        setSelectedShop('all');
        if (selectedTags.length === 0) {
            setShopsFilteredByTags(shops);
        } else {
            let newFilteredShops = [];
            shops.forEach(shop => {
                if (anyMatchInArray(shop.tags, selectedTags)) {
                    newFilteredShops = [...newFilteredShops, shop]
                }
            });
            setShopsFilteredByTags(newFilteredShops);
        }
    }, [selectedTags, shops]);

    const value = {
        shops,
        addNewShopToAllShopsAndFilteredShops,
        editShop,
        deleteShopFromAllShopsAndFilteredShops,
        setShops,
        selectedShop,
        setSelectedShop,
        selectedTags,
        setSelectedTags,
        shopsFilteredByTags,
        setShopsFilteredByTags,
        loadingShops
    };

    return (
        <ShopContext.Provider value={value}>
            { children}
        </ShopContext.Provider>
    )
}