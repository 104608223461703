import { useState } from "react";

const useInput = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (e) => setValue(e.target.value);

  const clearInput = () => {
    setValue('');
  }

  return { value, setValue, onChange, clearInput };
};

export default useInput;