import React, { useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import {
    FormControl,
    InputLabel,
    Select,
    Input,
    Checkbox,
    ListItemText,
    IconButton,
    TextField,
    makeStyles
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { useTag } from '../../contexts/TagContext';
import useInput from '../../hooks/useInput';


const useStyles = makeStyles((theme) => ({
    formControlFlatform: {
        margin: theme.spacing(1),
        minWidth: 150,
        maxWidth: 300
    },
    formControlTags: {
        margin: theme.spacing(1),
        minWidth: 370,
        maxWidth: 500
    },
    addTagTexField: {
        margin: theme.spacing(3),
        minWidth: 300
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function TagsCRUD({ newTag, setNewTag, selectedTags, setSelectedTags }) {
    const [editTagMode, setEditTagMode] = useState(false);
    const [selectedEditTag, setSelectedEditTag] = useState({});

    const classes = useStyles();
    const { tags, addTag, deleteTag, editTag, setTags } = useTag();
    const tagChange = useInput('');

    const SendButton = () => (
        <IconButton onClick={() => {
            addTag(newTag);
            setNewTag('')
        }}>
            <SendIcon />
        </IconButton>
    )

    const EditDoneButton = ({ tag }) => (
        <IconButton color="primary" onClick={() => {
            const _selectedTags = selectedTags;
            const _tags = tags;

            editTag(tag.id, tagChange.value);
            for (let i = 0; i < _selectedTags.length; i++) {
                if (_selectedTags[i] === tag.tagname) {
                    _selectedTags[i] = tagChange.value;
                }
            }
            for (let i = 0; i < _tags.length; i++) {
                if (_tags[i].id === tag.id) {
                    _tags[i].tagname = tagChange.value;
                }
            }
            setSelectedTags(_selectedTags);
            setTags(_tags);
            setEditTagMode(false);
        }}>
            <SendIcon />
        </IconButton>
    )

    const openEditTagMode = (tag) => {
        setSelectedEditTag(tag);
        setEditTagMode(true);
    }

    const handleChangeTag = (e) => {
        setSelectedTags(e.target.value);
    }

    const handleDeleteTag = (tag) => {
        setSelectedTags([]);
        deleteTag(tag.id);

    }

    const handleChange = (e) => {
        setNewTag(e.target.value);
    }

    return (
        <FormControl className={classes.formControlTags}>
            <InputLabel id="demo-mutiple-checkbox-label">Tag</InputLabel>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedTags}
                onChange={handleChangeTag}
                input={<Input />}
                renderValue={(selected) => selected.join(' ')}
                MenuProps={MenuProps}
                onClose={() => setEditTagMode(false)}
            >
                {!editTagMode && (
                    <TextField
                        className={classes.addTagTexField}
                        id="addTag"
                        label="Add a new tag"
                        value={newTag}
                        onChange={handleChange}
                        InputProps={{ endAdornment: <SendButton /> }}
                    />
                )}
                {!editTagMode ? (
                    tags.map((tag) => (
                        <ListItem key={tag.id} value={tag.tagname}>
                            <Checkbox checked={selectedTags.indexOf(tag.tagname) > -1} />
                            <ListItemText primary={'#' + tag.tagname} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => { openEditTagMode(tag) }}
                                >
                                    <EditIcon color='primary' />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteTag(tag)} aria-label="delete">
                                    <DeleteIcon color='secondary' />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                    )) : (
                    <TextField
                        className={classes.addTagTexField}
                        id="editTag"
                        value={tagChange.value}
                        onChange={tagChange.onChange}
                        placeholder="Edit tag"
                        InputProps={{ endAdornment: <EditDoneButton tag={selectedEditTag} /> }}
                    />
                )
                }
            </Select>
        </FormControl>
    )
}
