import React from 'react'
import AddShopModal from '../components/ShopManage/AddShopModal';
import ShopTable from '../components/ShopManage/ShopTable';
import { makeStyles } from '@material-ui/core/styles';
import Appbar from '../components/Navbar/Appbar';

const useStyles = makeStyles({
    root: {
        padding: 20
    },
})

export default function ShopManage() {
    const classes = useStyles();

    return (
        <>
            <Appbar />
            <div className={classes.root}>
                <AddShopModal />
                <br></br>
                <ShopTable />
            </div>
        </>

    )
}
