import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const app = firebase.initializeApp({
    // apiKey: "AIzaSyDHRPmmLkgGVHkKBO1zCR4Nn7uIIlfS1K0",
    // authDomain: "gnspy-debug.firebaseapp.com",
    // projectId: "gnspy-debug",
    // storageBucket: "gnspy-debug.appspot.com",
    // messagingSenderId: "441422582516",
    // appId: "1:441422582516:web:10d0314bfe8066a8c484a1",
    // measurementId: "G-XKF2EL1ELZ"
    apiKey: "AIzaSyCDaGBue_T3qd7BW8lWGbxvLAr5KMiyL84",
    authDomain: "e-scraper-f1c03.firebaseapp.com",
    projectId: "e-scraper-f1c03",
    storageBucket: "e-scraper-f1c03.appspot.com",
    messagingSenderId: "1049637460605",
    appId: "1:1049637460605:web:631a55fa890d4a79932f14",
    measurementId: "G-P9J4ER3V8C"
});

export const auth = app.auth();
export const firestore = app.firestore();