import { makeStyles } from '@material-ui/core'
import React from 'react'
import '../App.css'

const useStyles = makeStyles({
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -50,
        marginLeft: -50,
        width: 100,
        height: 100
    }
})

export default function Test() {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.center}>
                <div className="sk-chase">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                </div>
            </div>
        </div>
    )
}
