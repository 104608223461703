import React from 'react';
import moment from 'moment';

import { makeStyles, MenuItem } from '@material-ui/core';

import { useShop } from '../../contexts/ShopContext';
import { useProduct } from '../../contexts/ProductContext';

const useStyles = makeStyles({
    notificationImage: {
        width: 50, 
        height: 50, 
        marginRight: 10
    },
    notificationText: {
        fontSize: 15, 
        marginTop: 0
    }
})

export default function NotificationItem({notification, setAnchorEl}) {
    const { selectedShop, setSelectedShop } = useShop();
    const { setProducts, setCurrentPage } = useProduct();
    const classes = useStyles();

    return (
        <MenuItem onClick={() => {
            if (notification.shopUrl !== selectedShop) {
                setSelectedShop(notification.shopUrl);
                setProducts([]);
                setCurrentPage(1);
            }
            setAnchorEl(null);
        }}>
            <img src={notification.image} className={classes.notificationImage} alt="notification" />
            <div>
                <strong>{notification.shopUrl}</strong> added a new item
                <p className={classes.notificationText}>
                    {notification.flatform !== 'shopbase'
                        ? moment(notification.created_at).calendar()
                        : moment.unix(notification.created_at).calendar()
                    }
                </p>
            </div>
        </MenuItem>
    )
}
