import React from 'react'

import {
    FormControl,
    InputLabel,
    Select,
    ListItemText,
    makeStyles,
    Input,
    Checkbox,
    MenuItem
} from '@material-ui/core'

import { useProduct } from '../../contexts/ProductContext';
import { useTag } from '../../contexts/TagContext';

const useStyles = makeStyles({
    formControl: {
        minWidth: 300,
        maxWidth: 500
    },
    text: {
        fontWeight: 500,
        textAlign: 'center'
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function TagFilter() {
    const classes = useStyles();
    const { tags, selectedTags, setSelectedTags } = useTag();
    const { setProducts, setCurrentPage } = useProduct();

    const handleChangeTag = (e) => {
        setSelectedTags(e.target.value);
        setProducts([]);
        setCurrentPage(1);
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">Tags</InputLabel>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedTags}
                onChange={handleChangeTag}
                input={<Input />}
                renderValue={(selected) => selected.join(' ')}
                MenuProps={MenuProps}
            >
                {tags.length === 0
                    ? <p className={classes.text}>There are no tags yet.</p>
                    : tags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.tagname}>
                            <Checkbox checked={selectedTags.indexOf(tag.tagname) > -1} />
                            <ListItemText primary={tag.tagname} />
                        </MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}
