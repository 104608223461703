import { makeStyles } from '@material-ui/core';
import React from 'react';
import ShopSelect from './ShopSelect';
import TagFilter from './TagFilter';

const useStyles = makeStyles({
    Tools: {
        display: 'flex',
        margin: 20,
        justifyContent: 'center'
    }
})

export default function Tools() {
    const classes = useStyles();
    
    return (
        <div className={ classes.Tools }>
            <ShopSelect />
            <TagFilter />
        </div>
    )
}
