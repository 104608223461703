import React, { useState } from 'react';

import {
    Button
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useShop } from '../../contexts/ShopContext';
import TagsCRUD from './TagsCRUD';

export default function EditShopModal(
    { open, setOpen, selectedEditShop }
) {
    const { editShop } = useShop();
    const [selectedTags, setSelectedTags] = useState([]);
    const [newTag, setNewTag] = useState('');

    const handleEditDone = () => {
        editShop(selectedEditShop.id, selectedEditShop.url, selectedEditShop.flatform, selectedTags);
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        setNewTag('');
    };

    return (
        <div>
            <Dialog open={open} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit shop</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        label="Shop URL"
                        type="text"
                        value={selectedEditShop.url}
                        fullWidth
                        disabled={true}
                    />
                    <TagsCRUD newTag={newTag} setNewTag={setNewTag} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={()=>{
                        handleEditDone();
                    }} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
