import React, { useEffect, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Menu } from '@material-ui/core';

import NotificationItem from './NotificationItem';
import { firestore } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';

const useStyles = makeStyles({
    menu: {
        top: 25
    },
    notificationTitle: {
        marginTop: 0, 
        marginBottom: 0, 
        marginLeft: 12
    }
});

export default function Notification() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [newNotifications, setNewNotifications] = useState([]);
    const [recentNotifications, setRecentNotifications] = useState([]);
    const [badgeContent, setBadgeContent] = useState(0);
    
    const { currentUser } = useAuth();
    const classes = useStyles();

    const open = Boolean(anchorEl);

    useEffect(() => {
        setBadgeContent(newNotifications.length);
    }, [newNotifications])

    useEffect(() => {
        fetchNewNotifications();
        fetchRecentNotifications();
    }, []);

    const fetchNewNotifications = async () => {
        setNewNotifications([]);

        let newProductsQuery = firestore.collection('new_products');
        newProductsQuery = newProductsQuery.where('seen', '==', false);
        newProductsQuery = newProductsQuery.where('userId', '==', currentUser.uid);
        const newProductsSnapshot = await newProductsQuery.get();

        newProductsSnapshot.forEach(doc => {
            let newProduct = doc.data();
            newProduct.docId = doc.id;
            setNewNotifications(newNotifications => [...newNotifications, newProduct]);
        });
    }

    const fetchRecentNotifications = async () => {
        setRecentNotifications([]);

        let recentProductsQuery = firestore.collection('new_products');
        recentProductsQuery = recentProductsQuery.where('seen', '==', true);
        recentProductsQuery = recentProductsQuery.where('userId', '==', currentUser.uid);
        recentProductsQuery = recentProductsQuery.orderBy('added_at').limit(20);
        const recentProductsSnapshot = await recentProductsQuery.get();

        recentProductsSnapshot.forEach(doc => {
            let recentProduct = doc.data();
            recentProduct.docId = doc.id;
            setRecentNotifications(recentNotifications => [...recentNotifications, recentProduct]);
        });
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        newNotifications.forEach(async newNoti => {
            const newProductRef = firestore.collection('new_products').doc(newNoti.docId);
            await newProductRef.update({ seen: true });
        });
        setBadgeContent(0);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-label="notifications" onClick={handleMenu} color="inherit">
                <Badge badgeContent={badgeContent} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={open}
                onClose={handleClose}
                className={classes.menu}
            >
                {newNotifications.length !== 0 && <h2 className={classes.notificationTitle}>New({newNotifications.length})</h2>}
                {newNotifications.map(noti => (
                    <NotificationItem key={noti.docId} notification={noti} setAnchorEl={setAnchorEl} />
                ))}
                <h2 className={classes.notificationTitle}>Recent</h2>
                {recentNotifications.map(noti => (
                    <NotificationItem key={noti.docId} notification={noti} setAnchorEl={setAnchorEl} />
                ))}
            </Menu>
        </>
    )
}
