import React, { useEffect, useState } from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles({
    toTop: {
        zIndex: 2,
        position: 'fixed',
        bottom: '2vh',
        backgroundColor: 'red',
        color: 'white',
        "&:hover, &.Mui-focusVisible": {
            transition: '0.3s',
            color: "#397BA6",
            backgroundColor: '#DCDCDC'
        },
        right: '5%'
    }
});

export default function ScrollToTop({ showBelow }) {
    const [show, setShow] = useState(showBelow ? true: false);
    const classes = useStyles();
    
    const handleClick = () => {
        window[`scrollTo`]({top: 0, behavior: 'smooth'});
    }

    const handleScroll = () => {
        if(window.pageYOffset > showBelow){
            if(!show) setShow(true);
        } else {
            if(show) setShow(false);
        }
    }

    useEffect(() => {
        if(showBelow){
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    })

    return (
        <div>
            {show && 
            <IconButton onClick={ handleClick } className={ classes.toTop }>
                <ExpandLessIcon />
            </IconButton>}
        </div>
    )
}
