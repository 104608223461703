import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css'

import { ProductProvider } from './contexts/ProductContext'
import { ShopProvider } from './contexts/ShopContext'
import { TagProvider } from './contexts/TagContext'
import { AuthProvider } from './contexts/AuthContext'

import Home from './pages/Home'
import ShopManage from './pages/ShopManage'
import Login from './pages/Login'
import SignUp from './pages/SignUp'

import PrivateRoute from './components/PrivateRoute'
import { BlackListProvider } from './contexts/BlackListContext'

function App() {
  return (
    <div className='App'>
      <Router>
        <AuthProvider>
          <TagProvider>
            <ShopProvider>
              <BlackListProvider>
                <ProductProvider>
                  <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/signup' component={SignUp} />
                    <PrivateRoute path='/' exact component={Home} />
                    <PrivateRoute path='/shops' exact component={ShopManage} />
                  </Switch>
                </ProductProvider>
              </BlackListProvider>
            </ShopProvider>
          </TagProvider>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App
