import { createContext, useContext, useEffect, useState } from "react";
import axios from 'axios';

import { useShop } from "./ShopContext";
import { useAuth } from "./AuthContext";
import { useBlackList } from "./BlackListContext";

const ProductContext = createContext();

export function useProduct() {
    return useContext(ProductContext);
}

export function ProductProvider({ children }) {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const { currentUser } = useAuth();
    const { selectedShop, shopsFilteredByTags, shops } = useShop();
    const { blackList } = useBlackList();

    // reset page and products
    useEffect(() => {
        setProducts([]);
        setCurrentPage(1);
    }, [currentUser]);

    useEffect(() => {
        if (selectedShop === 'all') {
            shopsFilteredByTags.forEach(shop => {
                fetchProducts(shop);
            });
        } else {
            const shop = shopsFilteredByTags.find(shop => shop.url === selectedShop);
            fetchProducts(shop);
        }
    }, [selectedShop, shopsFilteredByTags, shops]);

    const loadMore = () => {
        if (selectedShop === 'all') {
            shopsFilteredByTags.forEach(shop => {
                fetchProducts(shop);
            });
        } else {
            const shop = shopsFilteredByTags.find(shop => shop.url === selectedShop);
            fetchProducts(shop);
        }
    }

    const fetchProducts = async (shop) => {
        const isInBlackList = blackList.some(url => shop.url.includes(url))
        if (isInBlackList) return
        
        switch (shop.flatform) {
            case 'shopify': {
                const fetchUrl = `${shop.url}/products.json?limit=30&page=${currentPage}`;
                const res = await axios.get(fetchUrl);
                res.data.products.forEach(e => {
                    e.flatform = shop.flatform;
                    e.shopUrl = shop.url;
                })
                setProducts(products => [...products, ...res.data.products]);
                break;
            }
            case 'woocommerce': {
                const fetchUrl = `${shop.url}/wp-json/wp/v2/product?per_page=30&page=${currentPage}`;
                const res = await axios.get(fetchUrl);
                res.data.forEach(e => {
                    e.flatform = shop.flatform;
                    e.shopUrl = shop.url;
                })
                setProducts(products => [...products, ...res.data]);
                break;
            }
            case 'shopbase': {
                const fetchUrl = `${shop.url}/api/catalog/next/products.json?limit=30&page=${currentPage}`;
                const res = await axios.get(fetchUrl);
                if (res.data.result) {
                    res.data.result.items.forEach(e => {
                        e.flatform = shop.flatform;
                        e.shopUrl = shop.url;
                    })
                    setProducts(products => [...products, ...res.data.result.items]);
                }
                break;
            }
            default:
                break;
        }

        if (shopsFilteredByTags.length > 0) {
            setCurrentPage(currentPage => currentPage + 1);
        }
    }

    
    const value = {
        products, loadMore, setProducts, setCurrentPage
    };

    return (
        <ProductContext.Provider value={value}>
            {children}
        </ProductContext.Provider>
    )
}